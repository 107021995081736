import BGPh1 from "../../assets/images/backgrounds/faq-bg.jpg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollToTopButton from "../ScrollToTopButton";

const Faqs = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "Czy przyciemnianie szyb w moim samochodzie jest legalne?",
      answer:
        "Przyciemnianie szyb jest legalne do pewnego stopnia, który różni się w zależności od jurysdykcji. Szyby czołowe i przednie boczne muszą charakteryzować się współczynnikiem przepuszczalności światła na poziomie nie mniejszym niż 70%. Wszystkie szyby za kierowcą pozwalają na pełną dowolność (nawet 100% przyciemnienia lub zmiana koloru). Najjaśniejsze folie dostępne na rynku mają przepuszczalność światła na poziomie 70%, 80%. Posiadamy w ofercie atermiczne folie serii Chameleon UltraVision, które można montować i są legalne na szybę czołową i przednie kierowcy i pasażera.",
    },
    {
      question:
        "Czy funkcja podgrzewania szyby będzie nadal działać prawidłowo po nałożeniu folii przyciemniającej?",
      answer:
        "Tak. Warto jednak pamiętać, że wybór właściwej folii i jej profesjonalna aplikacja są kluczowe, aby zapewnić, że podgrzewanie szyby nie zostanie zakłócone. Dobrze wykonana instalacja folii nie powinna negatywnie wpływać na funkcję podgrzewania, ponieważ folie przyciemniające są zazwyczaj tak zaprojektowane, aby nie blokować promieni podczerwonych, które są używane w systemach podgrzewania szyb.",
    },
    {
      question: "Demontujecie szyby do oklejania?",
      answer:
        "Glass Bocian to pionier bezinwazyjnej techniki nakładania folii na szyby. Nasza metoda nie wymaga demontażu szyb ani uszkadzania ich ram. Priorytetem jest minimalna ingerencja w pierwotny stan powierzonych pojazdów.",
    },
    {
      question:
        "Czy przyciemnianie szyb wpływa na widoczność podczas jazdy nocą?",
      answer:
        "Przyciemnianie szyb może nieco zmniejszyć widoczność w nocy, ale nasza firma zapewnia, że poziom przyciemnienia będzie zgodny z lokalnymi przepisami, aby nie zagrażał bezpieczeństwu.",
    },
    {
      question:
        "Z jakiej strony odbywa się proces przyciemniania szyb w samochodzie?",
      answer:
        "Przyciemnianie szyb odbywa się od wewnątrz pojazdu, co zapobiega narażeniu folii przyciemniającej na kontakt z elementami zewnętrznymi, takimi jak kamyki podczas jazdy. Dodatkowo, wewnętrzna aplikacja folii zapobiega ryzyku jej zdrapywania, na przykład podczas odśnieżania samochodu.",
    },
    {
      question: "Czym jest folia ochronna PPF i dlaczego warto ją zastosować?",
      answer:
        "Folia ochronna PPF to przeźroczysta folia poliuretanowa, która chroni lakier samochodu przed zarysowaniami, kamieniami i innymi drobnymi uszkodzeniami. Jest to doskonały sposób na zachowanie wyglądu samochodu, jak nowego, bez wpływu na oryginalny kolor i wykończenie.",
    },
    {
      question:
        "Jak długo folia ochronna PPF będzie trwała na moim samochodzie?",
      answer:
        "Folia ochronna PPF zwykle trwa kilka lat, ale jej żywotność może zależeć od warunków eksploatacji i pielęgnacji. Oferujemy gwarancję na nasze usługi, więc możesz być spokojny.",
    },
  ];

  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{ backgroundImage: `url(${BGPh1})` }}
        ></div>

        <div className="container">
          <h2>Faqs</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Start</Link>
            </li>
            <li>/</li>
            <li>
              <span>Faqs</span>
            </li>
          </ul>
        </div>
      </section>

      <div className="faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="thm-accordion" id="thm-accordion-one">
                {faqs.map((item, index) => (
                  <div
                    className={`thm-accordion__item wow fadeInUp ${
                      index === clicked && "active-item"
                    }`}
                    key={index}
                    data-wow-delay="0ms"
                  >
                    <div
                      className="thm-accordion__title"
                      onClick={() => setClicked(index)}
                    >
                      {item.question}

                      <div className="thm-accordion__icon"></div>
                    </div>
                    {index === clicked && (
                      <div className="thm-accordion__content">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="faq-one__call">
                <h3 className="faq-one__call-title">Zadzwoń</h3>
                <p className="faq-one__call-text">
                  Masz ochotę na rozmowę z prawdziwym człowiekiem, a nie
                  komputerem? Zadzwoń do naszego biura, a połączymy Cię z kimś,
                  kto zna różnicę między folią ochronną a folią aluminiową. Ale
                  ostrzegamy - może to być zabawna rozmowa!
                </p>
                <a className="faq-one__call-number" href="tel:781-040-302">
                  781 040 302
                </a>
                <Link to="/contact" className="thm-btn">
                  Znajdź nas
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default Faqs;
