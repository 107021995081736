import BGPh1 from "../../assets/images/backgrounds/contact-bg.jpg";
import { Link } from "react-router-dom";
import React from "react";
import ScrollToTopButton from "../ScrollToTopButton";

const Contact = () => {
  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{ backgroundImage: `url(${BGPh1})` }}
        ></div>

        <div className="container">
          <h2>Kontakt</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Start</Link>
            </li>
            <li>/</li>
            <li>
              <span>Kontakt</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="contact-one">
        <div className="container">
          <div className="section-title">
            <span className="section-title__tagline">Zadzwoń do nas</span>
            <h2 className="section-title__title">
              Jesteśmy aby Ci pomóc <br />W każdej chwili.
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-one__content">
                <p className="contact-one__text">
                  Potrzebujesz nowego wyglądu dla swojego auta, czy po prostu
                  chcesz z nami pogadać o przyciemnianiu szyb? Daj nam znać! Nie
                  gryziemy, chyba że próbujesz zerwać folię samodzielnie. Wtedy
                  nie obiecujemy niczego!
                </p>
                <p className="contact-one__address">
                  Przedmiejska 6/10 <br /> 54-201 Wrocław
                </p>
                <ul className="contact-one__list list-unstyled">
                  <li>
                    <Link to="mailto:biuroglassbocian@gmail.com">
                      <i className="fa fa-envelope"></i>
                      biuroglassbocian@gmail.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:781-040-302">
                      <i className="fa fa-phone-square-alt"></i>781 040 302
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*<div className="col-lg-8">*/}
            {/*  <form*/}
            {/*    action="assets/inc/sendemail.php"*/}
            {/*    className="contact-form-validated contact-one__form"*/}
            {/*  >*/}
            {/*    <div className="row">*/}
            {/*      <div className="col-md-6">*/}
            {/*        <input type="text" placeholder="Twoje imię" name="name" />*/}
            {/*      </div>*/}
            {/*      <div className="col-md-6">*/}
            {/*        <input*/}
            {/*          type="email"*/}
            {/*          placeholder="Adres email"*/}
            {/*          name="email"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className="col-md-6">*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          placeholder="Numer telefonu"*/}
            {/*          name="phone"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className="col-md-6">*/}
            {/*        <input type="text" placeholder="Temat" name="subject" />*/}
            {/*      </div>*/}
            {/*      <div className="col-md-12">*/}
            {/*        <textarea*/}
            {/*          name="message"*/}
            {/*          placeholder="Napisz komentarz"*/}
            {/*        ></textarea>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-12">*/}
            {/*        <button type="submit" className="thm-btn">*/}
            {/*          Wyślij wiadomość*/}
            {/*          <i className="far fa-arrow-alt-circle-right"></i>*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*  <div className="result"></div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>

      <div className="google-map__contact-page">
        <iframe
          title="template google map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8423.248644660018!2d16.99099527024514!3d51.122355452832416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fea01037aabd9%3A0x767065016e9c214b!2sGlass%20Bocian%20Wroc%C5%82awskie%20Centrum%20Przyciemniania%20Szyb!5e0!3m2!1spl!2spl!4v1691442724802!5m2!1spl!2spl"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        >
          className="map__contact-page"
        </iframe>
      </div>

      <ScrollToTopButton />
    </>
  );
};
export default Contact;
