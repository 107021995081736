import BGPh1 from "../../assets/images/backgrounds/about-bg.jpg";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ScrollToTopButton from "../ScrollToTopButton";
import React from "react";

const About = () => {
  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{ backgroundImage: `url(${BGPh1})` }}
        ></div>

        <div className="container">
          <h2>O nas</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Start</Link>
            </li>
            <li>/</li>
            <li>
              <span>O nas</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="about-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src="assets/images/resources/about-1-1.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <img
                src="assets/images/resources/about-1-2.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title">
                <span className="section-title__tagline">Zadzwoń do nas</span>
                <h2 className="section-title__title">
                  Specjalizujemy się w oklejaniu i przyciemnianiu
                </h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="about-one__content">
                <p className="about-one__highlighted">
                  Mamy 12+ lat doświadczenia, dzięki którym zapewniamy Ci
                  doskonałe wyniki.
                </p>
                <p className="about-one__text">
                  Nasza oferta jest zróżnicowana i dostosowana do indywidualnych
                  potrzeb. Od przyciemniania szyb po ochronę lakieru,
                  gwarantujemy profesjonalizm i precyzję, bez demontażu szyb i
                  tapicerki.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="section-separator" />
      <div className="client-carousel">
        <div className="container">
          <Swiper
            className="swiper-container thm-swiper__slider"
            modules={[Autoplay]}
            spaceBetween={140}
            slidesPerView={5}
            autoplay={{ delay: 5000 }}
            breakpoints={{
              0: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              375: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              575: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              767: {
                spaceBetween: 50,
                slidesPerView: 4,
              },
              991: {
                spaceBetween: 50,
                slidesPerView: 5,
              },
              1199: {
                spaceBetween: 140,
                slidesPerView: 5,
              },
            }}
          >
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/logo.png" alt="" />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>

      <section className="team-about">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Poznaj nasz zespół</span>
            <h2 className="section-title__title">
              Poznaj speców od <br />
              profesjonalnych realizacji
            </h2>
          </div>
          <div
            className="row team-card__row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-6 col-xl-3">
              <div className="team-card">
                {/*<div className="team-card__image">*/}
                {/*  <img src="assets/images/team/lidia.jpg" alt="" />*/}
                {/*</div>*/}
                <div className="team-card__content">
                  <h3>Lidia</h3>
                  <p>Właściciel</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3">
              <div className="team-card">
                {/*<div className="team-card__image">*/}
                {/*  <img src="assets/images/team/team-1-2.jpg" alt="" />*/}
                {/*</div>*/}
                <div className="team-card__content">
                  <h3>Uliana</h3>
                  <p>Współwłaściciel</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="gallery-two">
        <Swiper
          className="swiper-container thm-swiper__slider"
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 5000 }}
          breakpoints={{
            376: {
              spaceBetween: 30,
              slidesPerView: 2,
            },
            576: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            768: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            992: {
              spaceBetween: 30,
              slidesPerView: 4,
            },
            1200: {
              spaceBetween: 30,
              slidesPerView: 5,
            },
          }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-1.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-1.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-2.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-2.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-3.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-3.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-4.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-4.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-5.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-5.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/1.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/1.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/2.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/2.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/3.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/3.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/4.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/4.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/5.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/5.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/6.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/6.jpeg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>

      <ScrollToTopButton />
    </>
  );
};
export default About;
