import React from "react";
import { Link } from "react-router-dom";

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        scrollToTop();
      }}
      className="scroll-to-top"
    >
      <i className="fa fa-angle-up"></i>
    </Link>
  );
};

export default ScrollToTopButton;
