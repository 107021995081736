import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar/Main";
import Footer from "../components/Footer/Main";
import Error from "../components/Error/Main";
import About from "../components/About/Main";
import Contact from "../components/Contact/Main";
import Faqs from "../components/Faqs/Main";
import Home from "../components/Home/Main";
import Services from "../components/Service/Main";
import Configurator from "../components/Configurator/Main";

const Routing = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<Error />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/config" element={<Configurator />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
