import BGPh1 from "../../assets/images/backgrounds/service-bg.jpg";
import { Link } from "react-router-dom";
import React from "react";
import ScrollToTopButton from "../ScrollToTopButton";

const Services = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="stricky-header stricked-menu main-menu">
          <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
          <div
            className="page-header__bg"
            style={{ backgroundImage: `url(${BGPh1})` }}
          ></div>

          <div className="container">
            <h2>Usługi</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Start</Link>
              </li>
              <li>/</li>
              <li>
                <span>Usługi</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="service-one">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-1.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">
                      Przyciemnianie szyb i lamp
                    </h3>
                    <p className="service-card__text">
                      Ta starannie wykończona procedura dodaje nie tylko
                      elegancji i tajemniczości wyglądowi Twojego pojazdu, ale
                      także podkreśla jego wyrafinowany charakter.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-2.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">Folia ochronna PPF</h3>
                    <p className="service-card__text">
                      Ta specjalna folia ma na celu zabezpieczenie powierzchni
                      karoserii przed różnymi uszkodzeniami, takimi jak
                      zadrapania, odpryski, przebarwienia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-3.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">Dechroming</h3>
                    <p className="service-card__text">
                      To doskonałe rozwiązanie dla tych, którzy pragną subtelnej
                      i wyrafinowanej zmiany wyglądu swojego pojazdu. Folia na
                      elementy chromowane lub dekory.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-4.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">Folia wylewna</h3>
                    <p className="service-card__text">
                      Jest to mocna folia, zaprojektowana głównie z myślą o
                      zmianie koloru karoserii samochodu, co pozwala na szybką i
                      efektowną modyfikację wyglądu.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-5.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">
                      Folia przeciwsłoneczna
                    </h3>
                    <p className="service-card__text">
                      Nakładamy folię przeciwsłoneczną na szyby w celu odbicia
                      promieni słonecznych i zredukowania ciepła oraz blasku w
                      pojazdach, a także witrynach sklepów / salonów.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-card__image">
                    <img src="assets/images/services/service-1-6.jpg" alt="" />
                  </div>
                  <div className="service-card__content">
                    <h3 className="service-card__title">
                      Indywidualne zlecenia
                    </h3>
                    <p className="service-card__text">
                      Nie tylko samochody wchodzą w grę. Dopasujemy wygląd lub
                      zebezpieczymy innego rodzaju maszyny, które pracują w
                      ciężkich warunkach.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default Services;
