import { Link } from "react-router-dom";
import React from "react";

export const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="footer-widget footer-widget__logo">
                  <Link to="/" className="footer-widget__logo">
                    <img src="assets/images/logo.png" width="100%" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <h3 className="footer-widget__title">
                    Media społecznościowe
                  </h3>
                  <p className="footer-widget__text">
                    Nasza firma specjalizuje się w przyciemnianiu szyb i lamp
                    oraz oklejaniu aut, oferując indywidualne rozwiązania i
                    najwyższą jakość usług na rynku.
                  </p>
                  <div className="footer-widget__social">
                    <a
                      href="https://www.facebook.com/profile.php?id=100086665992583"
                      target="_blank"
                      rel="noreferrer"
                      className="fab fa-facebook-square"
                      aria-label="Facebook"
                    >
                      <span className="visually-hidden">Facebook</span>
                    </a>
                    <a
                      href="https://www.instagram.com/glass_bocian_/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                      target="_blank"
                      rel="noreferrer"
                      className="fab fa-instagram"
                      aria-label="Instagram"
                    >
                      <span className="visually-hidden">Instagram</span>
                    </a>
                    <a
                      href="https://www.tiktok.com/@Glass_Bocian"
                      target="_blank"
                      rel="noreferrer"
                      className="fab fa-tiktok"
                      aria-label="TikTok"
                    >
                      <span className="visually-hidden">TikTok</span>
                    </a>
                    <a
                      href="https://www.google.com/search?q=glass+bocian+wroc%C5%82awskie+centrum+przyciemniania+szyb&oq=glass+bocian&aqs=chrome.0.69i59l3j69i61j69i60j69i61.6163j0j9&client=ms-android-oppo-rvo3&sourceid=chrome-mobile&ie=UTF-8&chrome_dse_attribution=1"
                      target="_blank"
                      rel="noreferrer"
                      className="fab fa-google"
                      aria-label="Google"
                    >
                      <span className="visually-hidden">Google</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="footer-widget footer-widget__contact">
                  <h3 className="footer-widget__title">Kontakt</h3>
                  <p className="footer-widget__text">
                    Przedmiejska 6/10 <br /> 54-201 Wrocław
                  </p>
                  <ul className="footer-widget__contact-list list-unstyled">
                    <li>
                      <Link to="mailto:biuroglassbocian@gmail.com">
                        <i className="fa fa-envelope"></i>
                        biuroglassbocian@gmail.com
                      </Link>
                    </li>
                    <li>
                      <Link to="tel:781-040-302">
                        <i className="fa fa-phone-square-alt"></i>781 040 302
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="bottom-footer__inner">
              <p translate="no" className="bottom-footer__text">
                © Wszystkie prawa zastrzeżone, 2023 Glass Bocian
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
