import React, { useState } from "react";
import { Link } from "react-router-dom";
import TintTile from "./TintTile";
import ScrollToTopButton from "../ScrollToTopButton";
import BGPh1 from "../../assets/images/backgrounds/config-bg.jpg";
import tintImage1 from "../../assets/images/config/tint0.jpg";
import tintImage7 from "../../assets/images/config/tint70.jpg";
import tintImage6 from "../../assets/images/config/tint50.jpg";
import tintImage4 from "../../assets/images/config/tint30.jpg";
import tintImage3 from "../../assets/images/config/tint15.jpg";
import tintImage2 from "../../assets/images/config/tint5.jpg";

const Configurator = () => {
  const tints = [
    { image: tintImage1, percentage: 0, label: "Brak" },
    { image: tintImage7, percentage: 70, label: "70%" },
    { image: tintImage6, percentage: 50, label: "50%" },
    { image: tintImage4, percentage: 30, label: "30%" },
    { image: tintImage3, percentage: 15, label: "15%" },
    { image: tintImage2, percentage: 5, label: "5%" },
  ];

  const [selectedTint, setSelectedTint] = useState(tints[0].image);
  const [opacity, setOpacity] = useState(1);

  const handleTintClick = (image) => {
    setOpacity(0);
    setTimeout(() => {
      setSelectedTint(image);
      setOpacity(1);
    }, 300);
  };

  return (
    <>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{ backgroundImage: `url(${BGPh1})` }}
        ></div>

        <div className="container">
          <h2>Konfigurator</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Start</Link>
            </li>
            <li>/</li>
            <li>
              <span>Konfigurator</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="configurator-section">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">
              Skonfiguruj przyciemnianie szyb
            </span>
            <h2 className="section-title__title">
              Chcesz sprawdzić <br />
              jak będzie wyglądać Twoje auto <br />
              po przyciemnianiu szyb?
            </h2>
          </div>
          <div className="car-image-container" style={{ opacity: opacity }}>
            <img src={selectedTint} alt="Car with selected tint" />
          </div>
          <div className="tint-tiles">
            {tints.map((tint, index) => (
              <TintTile
                key={index}
                {...tint}
                onClick={() => setSelectedTint(tint.image)}
              />
            ))}
          </div>
        </div>
      </section>

      <ScrollToTopButton />
    </>
  );
};
export default Configurator;
