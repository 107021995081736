const TintTile = ({ percentage, label, onClick }) => {
  const style = {
    filter: `grayscale(${percentage}%)`,
    cursor: "pointer",
  };

  return (
    <div className="tint-tile thm-btn" style={style} onClick={onClick}>
      <span>{label}</span>
    </div>
  );
};

export default TintTile;
