import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});
  const [cart, setcart] = useState(false);
  const [mobile, setmobile] = useState(false);

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    const activeMenu = () => {
      if (path === "/") {
        setmenu({ home: true });
      } else if (path === "/about") {
        setmenu({ about: true });
      } else if (path === "/services") {
        setmenu({ services: true });
      } else if (path === "/faqs") {
        setmenu({ faqs: true });
      } else if (path === "/config") {
        setmenu({ configurator: true });
      } else if (path === "/contact") {
        setmenu({ contact: true });
      } else {
        setmenu({ home: true });
      }
    };

    window.scroll(0, 0);
    activeMenu();
  }, [path]);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };
  return (
    <>
      <header className="main-header">
        <div className="topbar">
          <div className="container">
            <div className="topbar__inner">
              <ul className="list-unstyled topbar__infos">
                <li className="topbar__infos-item">
                  <Link to="#" className="topbar__infos-link">
                    <i className="far fa-clock"></i> Poniedziałek - Piątek 9:00
                    - 19:00
                  </Link>
                </li>
                <li className="topbar__infos-item">
                  <Link to="tel:781-040-302" className="topbar__infos-link">
                    <i className="fa fa-phone-alt"></i>781 040 302
                  </Link>
                </li>
              </ul>
              <div className="topbar__social">
                <a
                  href="https://www.facebook.com/profile.php?id=100086665992583"
                  target="_blank"
                  rel="noreferrer"
                  className="fab fa-facebook-square"
                  aria-label="Facebook"
                >
                  <span className="visually-hidden">Facebook</span>
                </a>
                <a
                  href="https://www.instagram.com/glass_bocian_/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                  className="fab fa-instagram"
                  aria-label="Instagram"
                >
                  <span className="visually-hidden">Instagram</span>
                </a>
                <a
                  href="https://www.tiktok.com/@Glass_Bocian"
                  target="_blank"
                  rel="noreferrer"
                  className="fab fa-tiktok"
                  aria-label="TikTok"
                >
                  <span className="visually-hidden">TikTok</span>
                </a>
                <a
                  href="https://www.google.com/search?q=glass+bocian+wroc%C5%82awskie+centrum+przyciemniania+szyb&oq=glass+bocian&aqs=chrome.0.69i59l3j69i61j69i60j69i61.6163j0j9&client=ms-android-oppo-rvo3&sourceid=chrome-mobile&ie=UTF-8&chrome_dse_attribution=1"
                  target="_blank"
                  rel="noreferrer"
                  className="fab fa-google"
                  aria-label="Google"
                >
                  <span className="visually-hidden">Google</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav
          className={`main-menu ${
            sticky && "stricky-header stricked-menu stricky-fixed"
          }`}
        >
          <div className="container">
            <div className="main-menu__inner">
              <div className="main-menu__logo">
                <Link to="/">
                  <img
                    src="assets/images/logo.png"
                    width="220px"
                    alt="Glass Bocian logo"
                  />
                </Link>
              </div>
              <ul className="main-menu__list">
                <li className={`dropdown ${menu.home && "current"}`}>
                  <Link to="/">Start</Link>
                </li>
                <li className={`dropdown ${menu.about && "current"}`}>
                  <Link to="/about">O nas</Link>
                </li>
                <li className={`dropdown ${menu.services && "current"}`}>
                  <Link to="/services">Usługi</Link>
                </li>
                <li className={`dropdown ${menu.faqs && "current"}`}>
                  <Link to="/faqs">Faqs</Link>
                </li>
                <li className={`dropdown ${menu.configurator && "current"}`}>
                  <Link to="/config">Konfigurator</Link>
                </li>
              </ul>
              <div className="main-menu__right">
                <Link
                  to="#"
                  onClick={() => setmobile(true)}
                  className="mobile-nav__toggler"
                >
                  <i className="fa fa-bars"></i>
                </Link>
                <Link to="/contact" className="thm-btn">
                  Kontakt
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`mini-cart ${cart ? "expanded" : ""}`}>
        <div
          className="mini-cart__overlay mini-cart__toggler"
          onClick={() => setcart(false)}
        ></div>
        <div className="mini-cart__content">
          <div className="mini-cart__top">
            <h3 className="mini-cart__title">Shopping Cart</h3>
            <span className="mini-cart__close mini-cart__toggler">
              <i className="organik-icon-close"></i>
            </span>
          </div>
          <div className="mini-cart__item">
            <img src="assets/images/products/product-s-1.jpg" alt="" />
            <div className="mini-cart__item-content">
              <div className="mini-cart__item-top">
                <h3>
                  <Link to="/shopdetails">Tire with Wheel </Link>
                </h3>
                <p>$3.99</p>
              </div>
              <div className="quantity-box">
                <button type="button" className="sub">
                  -
                </button>
                <input type="number" id="1" defaultValue="1" />
                <button type="button" className="add">
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="mini-cart__item">
            <img src="assets/images/products/product-s-2.jpg" alt="" />
            <div className="mini-cart__item-content">
              <div className="mini-cart__item-top">
                <h3>
                  <Link to="/shopdetails">Car Breaks </Link>
                </h3>
                <p>$120.99</p>
              </div>
              <div className="quantity-box">
                <button type="button" className="sub">
                  -
                </button>
                <input type="number" id="2" defaultValue="1" />
                <button type="button" className="add">
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="mini-cart__item">
            <img src="assets/images/products/product-s-3.jpg" alt="" />
            <div className="mini-cart__item-content">
              <div className="mini-cart__item-top">
                <h3>
                  <Link to="/shopdetails">Oil Filters </Link>
                </h3>
                <p>$9.99</p>
              </div>
              <div className="quantity-box">
                <button type="button" className="sub">
                  -
                </button>
                <input type="number" id="3" defaultValue="1" />
                <button type="button" className="add">
                  +
                </button>
              </div>
            </div>
          </div>
          <Link to="#" className="thm-btn mini-cart__checkout">
            Proceed To Checkout{" "}
            <i className="far fa-arrow-alt-circle-right"></i>
          </Link>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src="assets/images/logo.png" width="250" alt="" />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current">
                <Link to="/" onClick={() => setmobile(false)}>
                  Start
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setmobile(false)}>
                  O nas
                </Link>
              </li>
              <li>
                <Link to="services" onClick={() => setmobile(false)}>
                  Usługi
                </Link>
              </li>
              <li>
                <Link to="/faqs" onClick={() => setmobile(false)}>
                  Faqs
                </Link>
              </li>
              <li>
                <Link to="/config" onClick={() => setmobile(false)}>
                  Konfigurator
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:biuroglassbocian@gmail.com">
                biuroglassbocian@gmail.com
              </Link>
            </li>
            <li>
              <i className="fa fa-phone-square-alt"></i>
              <Link to="tel:781-040-302">781 040 302</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <a
                href="https://www.facebook.com/profile.php?id=100086665992583"
                target="_blank"
                rel="noreferrer"
                className="fab fa-facebook-square"
                aria-label="Facebook"
              >
                <span className="visually-hidden">Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/glass_bocian_/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                target="_blank"
                rel="noreferrer"
                className="fab fa-instagram"
                aria-label="Instagram"
              >
                <span className="visually-hidden">Instagram</span>
              </a>
              <a
                href="https://www.tiktok.com/@Glass_Bocian"
                target="_blank"
                rel="noreferrer"
                className="fab fa-tiktok"
                aria-label="TikTok"
              >
                <span className="visually-hidden">TikTok</span>
              </a>
              <a
                href="https://www.google.com/search?q=glass+bocian+wroc%C5%82awskie+centrum+przyciemniania+szyb&oq=glass+bocian&aqs=chrome.0.69i59l3j69i61j69i60j69i61.6163j0j9&client=ms-android-oppo-rvo3&sourceid=chrome-mobile&ie=UTF-8&chrome_dse_attribution=1"
                target="_blank"
                rel="noreferrer"
                className="fab fa-google"
                aria-label="Google"
              >
                <span className="visually-hidden">Google</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
