import BGImg from "../../assets/images/backgrounds/home_1.jpg";
import BGImg1 from "../../assets/images/backgrounds/home_2.jpg";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import AnimatedNumber from "animated-number-react";
import { jarallax } from "jarallax";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ScrollToTopButton from "../ScrollToTopButton";

const Home = () => {
  const activeRef = useRef(null);

  useEffect(() => {
    jarallax(activeRef.current, {
      speed: 0.3,
    });
  }, []);

  return (
    <>
      <section className="main-slider">
        <Swiper
          className="swiper-container thm-swiper__slider"
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: "#main-slider__swiper-button-next",
            prevEl: "#main-slider__swiper-button-prev",
          }}
          autoplay={{ delay: 5000 }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{ backgroundImage: `url(${BGImg})` }}
                ></div>

                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2>
                        Twoje auto <br />
                        Nasza pasja
                      </h2>
                      <Link to="/config" className="thm-btn">
                        Konfiguator
                        <i className="far fa-arrow-alt-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{ backgroundImage: `url(${BGImg1})` }}
                ></div>

                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2>
                        Styl <br />
                        Ochrona <br />
                        Dyskrecja
                      </h2>
                      <Link to="/config" className="thm-btn">
                        Konfiguator
                        <i className="far fa-arrow-alt-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>

          <div className="main-slider__nav">
            <div
              className="swiper-button-prev"
              id="main-slider__swiper-button-next"
            >
              <i className="crsine-left-arrow"></i>
            </div>
            <div
              className="swiper-button-next"
              id="main-slider__swiper-button-prev"
            >
              <i className="crsine-right-arrow"></i>
            </div>
          </div>
        </Swiper>
      </section>

      <section className="about-two">
        <div className="container">
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6">
              <div
                className="about-two__image wow fadeInRight"
                data-wow-duration="1500ms"
              >
                <img
                  src="assets/images/resources/about-2-1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-two__content">
                <div className="section-title">
                  <span className="section-title__tagline">Zadzwoń do nas</span>
                  <h2 className="section-title__title">
                    Najwyższy czas aby <br />
                    zadbać o elegancję
                  </h2>
                </div>
                <div className="about-two__summery">
                  {" "}
                  Nasza firma specjalizuje się w przyciemnianiu szyb i oklejaniu
                  aut, dostarczając usługi najwyższej jakości, które podkreślają
                  styl i elegancję Twojego pojazdu. Niezależnie od tego, czy
                  chcesz dodać prywatności, poprawić wygląd czy zabezpieczyć
                  swoje auto, jesteśmy tu, aby Ci pomóc.
                </div>
                <ul className="about-two__list list-unstyled">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Zadowolenie klienta na pierwszym miejscu
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Profesjonalne przyciemnianie i oklejanie
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Najwyższej jakości materiały
                  </li>
                </ul>
                <hr className="section-separator" />
                <div className="about-two__icon-box">
                  <i className=" crsine-car-service-3 about-two__icon"></i>
                  <div className="about-two__icon-content">
                    Mamy ponad 12 lat doświadczenia, aby zapewnić lepszą jakość
                    wyników.
                  </div>
                </div>
                <div className="about-two__button-box">
                  <Link to="/contact" className="thm-btn">
                    Znajdź nas
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </Link>
                  <div className="about-two__phone">
                    <i className="crsine-phone-call"></i>
                    <div className="about-two__phone-content">
                      <span>Zadzwoń w dowolnej chwili</span>
                      <Link to="tel:781-040-302">781 040 302</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-home">
        <img
          src="assets/images/backgrounds/service-bg-1.png"
          alt=""
          className="image-layer"
        />
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Czym się zajmujemy</span>
            <h2 className="section-title__title">
              Usługi, które prowadzimy dla <br />
              naszych klientów
            </h2>
          </div>

          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-4 wow fadeInLeft"
              data-wow-duration="1500ms"
            >
              <div className="service-card">
                <div className="service-card__image">
                  <img src="assets/images/services/service-1-1.jpg" alt="" />
                </div>
                <div className="service-card__content">
                  <h3 className="service-card__title">
                    Przyciemnianie szyb i lamp
                  </h3>
                  <p className="service-card__text">
                    Specjalizujemy się w przyciemnianiu szyb i lamp,
                    dostosowując wygląd do Twoich potrzeb.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="service-card">
                <div className="service-card__image">
                  <img src="assets/images/services/service-1-2.jpg" alt="" />
                </div>
                <div className="service-card__content">
                  <h3 className="service-card__title">Folie ochronne PPF</h3>
                  <p className="service-card__text">
                    Oferujemy folie ochronne PPF, zabezpieczając Twoje auto
                    przed zarysowaniami.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 wow fadeInRight"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="service-card">
                <div className="service-card__image">
                  <img src="assets/images/services/service-1-3.jpg" alt="" />
                </div>
                <div className="service-card__content">
                  <h3 className="service-card__title">Dechroming</h3>
                  <p className="service-card__text">
                    Nasza usługa dechromingu pozwala na indywidualne
                    dostosowanie wyglądu elementów chromowanych.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-one__load text-center">
            <Link to="/services" className="thm-btn">
              Jest tego więcej
              <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </section>

      <section className="feature-lists">
        <div
          className="feature-lists__boxed jarallax"
          ref={activeRef}
          data-jarallax
        >
          <img alt="" className="jarallax-img" />
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="section-title">
                  <span className="section-title__tagline">Gwarancje</span>
                  <h2 className="section-title__title">
                    Centrum oklejania <br /> i przyciemniania
                  </h2>
                </div>
                <Link to="/contact" className="thm-btn">
                  Siedziba <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
              <div className="col-lg-8">
                <div className="feature-lists__wrap">
                  <ul className="list-unstyled feature-lists__list">
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Profesjonalne przyciemnianie szyb
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Oklejanie pojazdów folią ochronną
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Dechroming i personalizacja wyglądu
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Wybór różnorodnych wzorów i kolorów
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Szybka i niezawodna realizacja
                    </li>
                  </ul>
                  <ul className="list-unstyled feature-lists__list">
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Ochrona UV
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Specjalistyczne doradztwo{" "}
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Gwarancja satysfakcji{" "}
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Wykwalifikowany zespół
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Nowoczesne materiały{" "}
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Indywidualna oferta{" "}
                    </li>
                  </ul>
                  <ul className="list-unstyled feature-lists__list">
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Personalizacja stylu
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Ochrona przed kamieniami
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Odporność na blaknięcie
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Usługi na zamówienie
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Wyjątkowa dbałość
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i>
                      Szybka realizacja
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-one">
        <div className="video-one fadeInLeft" data-wow-duration="1500ms">
          <div className="video-one__content">
            <p className="video-one__text">Podchodzimy do tego na poważnie. </p>
          </div>
          <video autoPlay loop muted playsInline>
            <source src="assets/images/resources/gif.MOV" type="video/mp4" />
          </video>
        </div>
        <div className="container">
          <div className="section-title">
            <span className="section-title__tagline">Benefity</span>
            <h2 className="section-title__title">
              Dlaczego powinieneś wybrać <br />
              nasz serwis
            </h2>
          </div>
          <div className="feature-one__summery">
            Wybierając naszą firmę, zyskujesz dostęp do 12 lat doświadczenia w
            przyciemnianiu szyb i lamp oraz oklejaniu aut, z gwarancją używania
            tylko najwyższej jakości materiałów.
          </div>
          <ul className="list-unstyled feature-one__list">
            <li>
              <i className="fa fa-check-circle"></i>
              Rzetelność
            </li>
            <li>
              <i className="fa fa-check-circle"></i>
              Wykwalifikowany zespół
            </li>
          </ul>

          <ul className="list-unstyled feature-one__progress">
            <li className="feature-one__progress-item">
              <span className="feature-one__progress-title">
                Przyciemnianie
              </span>
              <div className="feature-one__progress-bar">
                <span
                  className="feature-one__progress-main"
                  style={{ width: "93%" }}
                >
                  <span className="feature-one__progress-count">93%</span>
                </span>
              </div>
            </li>
            <li className="feature-one__progress-item">
              <span className="feature-one__progress-title">Oklejanie</span>
              <div className="feature-one__progress-bar">
                <span
                  className="feature-one__progress-main"
                  style={{ width: "90%" }}
                >
                  <span className="feature-one__progress-count">90%</span>
                </span>
              </div>
            </li>
          </ul>

          <div className="feature-one__counter">
            <div className="feature-one__counter-content">
              <h3
                className="feature-one__counter-title odometer"
                data-count="7209"
              >
                <AnimatedNumber
                  value={7209}
                  duration={6000}
                  formatValue={(v) => Math.round(v)}
                />
              </h3>

              <p className="feature-one__counter-text">
                Zadowolonych klientów z przyciemniania
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="gallery-two__home-title">
        <div className="container">Zobacz naszą galerię</div>
      </div>

      <div className="gallery-two gallery-two__home">
        <Swiper
          className="swiper-container thm-swiper__slider"
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 5000 }}
          breakpoints={{
            376: {
              spaceBetween: 30,
              slidesPerView: 2,
            },
            576: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            768: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            992: {
              spaceBetween: 30,
              slidesPerView: 4,
            },
            1200: {
              spaceBetween: 30,
              slidesPerView: 5,
            },
          }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-1.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-1.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-2.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-2.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-3.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-3.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-4.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-4.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/gallery-2-5.jpg" alt="" />
                  <a
                    className="img-popup"
                    href="assets/images/gallery/gallery-2-5.jpg"
                  >
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/1.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/1.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/2.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/2.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/3.jpg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/3.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/4.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/4.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/5.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/5.jpg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="gallery-one__item">
                  <img src="assets/images/gallery/6.jpeg" alt="" />
                  <a className="img-popup" href="assets/images/gallery/6.jpeg">
                    <i className="crsine-plus"></i>
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>

      <section className="blog-home">
        <div className="container">
          <div className="blog-home__top">
            <div className="section-title">
              <span className="section-title__tagline">Napisz do nas</span>
              <h2 className="section-title__title">
                Jesteśmy tutaj aby Ci pomóc <br />w każdej chwili
              </h2>
            </div>

            <div className="blog-home__top-text">
              Dostarczamy profesjonalne usługi z zakresu <br /> przyciemniania
              szyb i lamp oraz okejania aut <br /> zawsze dostosowane do Twoich
              indywidualnych potrzeb, <br /> bez demontażu szyb i tapicerki.
            </div>
          </div>
          <div className="service-one__load text-center">
            <Link to="/faqs" className="thm-btn">
              FAQ
              <i className="fa fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </section>

      <hr className="section-separator" />
      <div className="client-carousel ">
        <div className="container">
          <Swiper
            className="swiper-container thm-swiper__slider"
            modules={[Autoplay]}
            spaceBetween={140}
            slidesPerView={5}
            autoplay={{ delay: 5000 }}
            breakpoints={{
              0: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              375: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              575: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              767: {
                spaceBetween: 50,
                slidesPerView: 4,
              },
              991: {
                spaceBetween: 50,
                slidesPerView: 5,
              },
              1199: {
                spaceBetween: 140,
                slidesPerView: 5,
              },
            }}
          >
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="swiper-slide">
                  <img src="assets/images/partners/3m.png" alt="3M logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src="assets/images/partners/avery.png"
                    alt="Avery Dennison logo"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src="assets/images/partners/oracal.png"
                    alt="Oracal logo"
                  />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default Home;
